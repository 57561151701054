import { useEffect } from 'react';
import useAuth from 'api/authProvider';
import { IntercomProvider as Provider, useIntercom } from 'react-use-intercom';
import { User } from 'backendTypes';

interface IntercomActionsProps {
  currentUser: User;
}

const IntercomToggle = ({ currentUser }: IntercomActionsProps) => {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    shutdown(); // Make sure that if there was previous session, it is cleaned up.
    if (currentUser && !currentUser.isStaff) {
      boot({
        userId: currentUser.id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        userHash: currentUser.intercomUserHash,
        avatar: currentUser.profilePicture
          ? {
              type: 'avatar',
              imageUrl: currentUser.profilePicture,
            }
          : undefined,
      });
    } else {
      shutdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    // Return in useEffect means when commponent is unmounted.
    return () => {
      shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

interface IntercomProviderProps {
  children: React.ReactNode;
}

const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const { currentUser } = useAuth();
  return (
    <Provider appId={process.env.REACT_APP_INTERCOM_ID || ''}>
      <IntercomToggle currentUser={currentUser} />
      {children}
    </Provider>
  );
};

export default IntercomProvider;
