import styled from '@emotion/styled';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Paragraph, Link } = Typography;

const Agreements = () => {
  const { t } = useTranslation();
  return (
    <StyledParagraph>
      <Link href="https://www.uspm.com/terms-of-use/" target="_blank">
        {t('auth.termsConditions')}
      </Link>{' '}
      &{' '}
      <Link href="https://www.uspm.com/privacy-policy/" target="_blank">
        {t('auth.privacyPolicy')}
      </Link>
    </StyledParagraph>
  );
};

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-secondary-grey);

  a {
    color: var(--text-secondary-grey);
  }
`;

export default Agreements;
