import React from 'react';
import '@fontsource/roboto';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from 'App';
import LayoutTheme from 'LayoutTheme';
import { ConfigProvider as PhoneInputConfigProvider } from 'antd-country-phone-input';
import { AuthProvider } from 'api/authProvider';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'react-router-dom';
import { PopupProvider } from 'utils/usePopup';
import en from 'world_countries_lists/data/countries/en/world.json';
import ErrorFallback from 'components/ErrorFallback';
import queryClient from './api/queryClient';
import browserHistory from './browserHistory';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Router history={browserHistory}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <PopupProvider>
            <AuthProvider>
              <PhoneInputConfigProvider locale={en}>
                <LayoutTheme>
                  <App />
                </LayoutTheme>
              </PhoneInputConfigProvider>
            </AuthProvider>
          </PopupProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ErrorBoundary>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
