import { notification } from 'antd';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from 'axios';
import redirectTo from 'utils/redirectTo';

const restClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || '/',
  headers: {
    'Content-Type': 'application/json',
  },
});

interface RequestConfig extends AxiosRequestConfig {
  _skipErrorHandler?: boolean;
  headers: AxiosResponseHeaders;
}

restClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err: any) => {
    const originalRequest = err.config as RequestConfig;
    if (err.response.status === 401 && restClient.defaults.headers.common.authorization) {
      const localStorageToken = window.localStorage.getItem('auth:accessToken');
      const localStorageAuthorization = `Token ${localStorageToken}`;
      // If authorization token was invalid and the one stored in localStorage has changed,
      // try to apply the one from localStorage and retry the request.
      if (
        !originalRequest._skipErrorHandler &&
        localStorageToken &&
        localStorageAuthorization !== restClient.defaults.headers.common.authorization
      ) {
        restClient.defaults.headers.common.authorization = localStorageAuthorization;
        originalRequest.headers.authorization = localStorageAuthorization;
        originalRequest._skipErrorHandler = true;
        return restClient(originalRequest);
      } else {
        delete restClient.defaults.headers.common.authorization;
        delete err.config.headers?.Authorization;
        window.localStorage.removeItem('auth:accessToken');
        const pathname = window.location.pathname;
        redirectTo('login', pathname);
        notification.info({ message: 'Your session has expired.' });
        return null;
      }
    }
    return Promise.reject(err);
  }
);

export default restClient;
