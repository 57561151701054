import { ReactNode, useEffect, useMemo, useState } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { Button, Drawer, Layout, Menu, MenuProps, Popover, Typography } from 'antd';
import {
  AuditOutlined,
  FieldTimeOutlined,
  HeartOutlined,
  LogoutOutlined,
  MenuOutlined,
  StarOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import useAuth from 'api/authProvider';
import { backend } from 'api/backend';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import IntercomProvider from 'utils/IntercomProvider';
import FooterAgreements from 'components/FooterAgreements';
import UserAvatar from 'components/UserAvatar';
import { PageParams } from 'backendTypes';
import { Logo } from 'assets';

const { Header, Content } = Layout;
const { Text } = Typography;

interface SignOutWrapperProps {
  popover: boolean;
}

interface LayoutDashboardProps {
  children: ReactNode;
}

const LayoutDashboard = ({ children }: LayoutDashboardProps) => {
  const { logout, currentUser, hasActivePursuit } = useAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams<PageParams>();
  const { pathname } = useLocation();
  const profileId = currentUser.isStaff ? (id ? id : undefined) : currentUser.id;
  const showPageNavigation = pathname === '/assessment' ? false : true;

  // Close popover on window resize.
  useEffect(() => {
    const handleResize = () => {
      setOpenPopover(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderedUserName = useMemo(
    () =>
      !currentUser.firstName && !currentUser.lastName
        ? currentUser.email
        : `${currentUser.firstName} ${currentUser.lastName}`,
    [currentUser]
  );

  const userProfileId = currentUser.isStaff && profileId ? profileId : '';

  const userDataEnabled = currentUser.isStaff && userProfileId;

  const { data: userData } = useQuery(['user'], () => backend.getUser(userProfileId), {
    enabled: !!userDataEnabled,
  });

  const rewardsDisabledForStaff = currentUser.isStaff && (!profileId || !userData?.hasUserAccount);

  const pages = [
    {
      key: 'metaOne',
      label: t('layout.metaOne'),
      link: '/meta-one',
      staffLink: profileId ? `/my-journey/${profileId}` : '/my-journey',
      icon: <HeartOutlined />,
      disabled: false,
    },
    {
      key: 'myJourney',
      label: t('layout.myJourney'),
      link: '/my-journey',
      staffLink: profileId ? `/my-journey/${profileId}` : '/my-journey',
      icon: <TrophyOutlined />,
      disabled: false,
      disabledForStaff: true,
    },
    {
      key: 'dashboard',
      label: t('layout.dashboard'),
      link: '/',
      staffLink: profileId ? `/profile/${profileId}` : '/',
      icon: <AuditOutlined />,
      disabled: false,
    },
    {
      key: 'rewards',
      label: t('layout.rewards'),
      link: '/rewards',
      staffLink: profileId ? `/rewards/${profileId}` : '/rewards',
      icon: <StarOutlined />,
      disabled: !currentUser.isStaff && !hasActivePursuit,
      disabledForStaff: rewardsDisabledForStaff,
    },
    {
      key: 'devices',
      label: t('layout.devices'),
      link: '/devices',
      staffLink: '/devices',
      icon: <FieldTimeOutlined />,
      disabled: !currentUser.isStaff && !hasActivePursuit,
      disabledForStaff: true,
    },
    {
      key: 'signOut',
      label: t('auth.signOut'),
      link: '/sign-out',
      staffLink: '/sign-out',
      icon: <LogoutOutlined />,
      disabled: false,
    },
  ];

  type MenuItem = Required<MenuProps>['items'][number];

  const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode): MenuItem => {
    return {
      key,
      icon,
      label,
    } as MenuItem;
  };

  const items: MenuProps['items'] = pages
    .filter(page => {
      const disabled = currentUser.isStaff ? page.disabledForStaff : page.disabled;
      return !disabled;
    })
    .map(page => {
      const link = currentUser.isStaff ? page.staffLink : page.link;
      if (page.key === 'signOut') {
        return getItem(page.label, page.key, page.icon);
      }
      return getItem(<Link to={link}>{page.label}</Link>, link, page.icon);
    });

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const onClick: MenuProps['onClick'] = e => {
    if (e.key === 'signOut') {
      logout();
      return;
    }
    onClose();
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
  };

  return (
    <IntercomProvider>
      <StyledLayout>
        <StyledHeader>
          <LogoWrapper>
            <Link to="/">
              <StyledLogo />
            </Link>
          </LogoWrapper>
          {currentUser && (
            <Profile>
              <SignOutWrapper popover={false}>
                <Text strong>{renderedUserName}</Text>
                <SignOutLink data-cy="logout-button" onClick={() => logout()}>
                  {t('auth.signOut')}
                </SignOutLink>
              </SignOutWrapper>
              <Popover
                placement="topRight"
                arrow={{ pointAtCenter: true }}
                content={
                  <Profile>
                    <SignOutWrapper popover={true}>
                      <Text strong>{renderedUserName}</Text>
                      <SignOutLink onClick={() => logout()}>{t('auth.signOut')}</SignOutLink>
                    </SignOutWrapper>
                    <UserAvatar
                      image={currentUser.profilePicture}
                      firstName={currentUser.firstName}
                      lastName={currentUser.lastName}
                    />
                  </Profile>
                }
                open={openPopover}
                onOpenChange={handleOpenChange}
                trigger="click"
              >
                <AvatarWrapper>
                  <UserAvatar
                    image={currentUser.profilePicture}
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                  />
                </AvatarWrapper>
              </Popover>
              <HamburgerMenu
                type="text"
                size="large"
                icon={<MenuOutlined />}
                onClick={showDrawer}
              />
            </Profile>
          )}
        </StyledHeader>
        <LayoutContainer>
          <Layout>
            {showPageNavigation && (
              <PageNavigation>
                <PageListWrapper>
                  <PageList>
                    {pages
                      .filter(page => page.key !== 'signOut' && page.key !== 'dashboard')
                      .filter(page => (currentUser.isStaff ? !page.disabledForStaff : 1))
                      .map(page => {
                        const link = currentUser.isStaff ? page.staffLink : page.link;

                        // exception for meta-one page
                        const isMetaOnePage = pathname.includes('meta-one');
                        const isMetaOneLink = link.includes('meta-one');
                        const isTaskPage = pathname.includes('task');
                        const metaOneException =
                          (isMetaOnePage && isMetaOneLink) || (isTaskPage && isMetaOneLink);

                        return (
                          <PageListItem key={page.key}>
                            <Link to={link}>
                              <Button
                                disabled={page.disabled}
                                icon={page.icon}
                                type={pathname === link || metaOneException ? 'primary' : 'default'}
                              >
                                {page.label}
                              </Button>
                            </Link>
                          </PageListItem>
                        );
                      })}
                  </PageList>
                  <DashboardItem>
                    <Link to="/">
                      <Button
                        icon={<AuditOutlined />}
                        type={pathname === '/' ? 'primary' : 'default'}
                      >
                        My Dashboard
                      </Button>
                    </Link>
                  </DashboardItem>
                </PageListWrapper>
              </PageNavigation>
            )}

            <Drawer title={renderedUserName} placement="right" onClose={onClose} open={openDrawer}>
              <StyledMenu mode="inline" items={items} selectedKeys={[pathname]} onClick={onClick} />
            </Drawer>
            <Layout>
              <StyledContent>{currentUser.hasAcceptedPolicy && children}</StyledContent>
            </Layout>
          </Layout>
        </LayoutContainer>
        <FooterAgreements />
      </StyledLayout>
    </IntercomProvider>
  );
};

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background-color: var(--background-secondary);
`;

const LayoutContainer = styled.div`
  max-width: var(--max-width);
  width: 100%;
  flex: 1;
  margin: 0 auto;

  @media screen and (min-width: 1466px) {
    width: var(--max-width);
  }
`;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: initial;
  background-color: var(--background-primary);
  box-shadow: var(--default-shadow);
  z-index: 1;
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  max-width: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const StyledLogo = styled(Logo)`
  width: 100%;
  max-width: 158px;
  margin: 0;
`;

const Profile = styled.div`
  display: flex;
`;

const SignOutWrapper = styled.div<SignOutWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 15px;
  @media (max-width: 767px) {
    display: ${props => (props.popover ? 'flex' : 'none')};
  }
`;

const AvatarWrapper = styled.div`
  pointer-events: none;

  @media (max-width: 767px) {
    pointer-events: all;
  }
`;

const SignOutLink = styled.button`
  font-size: 0.75rem;
  color: var(--text-grey);
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 5px;
  cursor: pointer;
`;

const HamburgerMenu = styled(Button)`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const StyledMenu = styled(Menu)`
  height: '100%';
  border-right: 0;
`;

const PageNavigation = styled.div`
  display: flex;
  background-color: var(--background-secondary);

  @media (max-width: 767px) {
    display: none;
  }
`;

const PageListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 3rem);
  align-items: center;
`;

const PageList = styled.ul`
  display: flex;
  list-style: none;
  margin: 1rem 0 0 3.125rem;
  padding: 0;
`;

const PageListItem = styled.li`
  margin-right: 10px;
`;

const DashboardItem = styled.div`
  margin-top: 1rem;
`;

const StyledContent = styled(Content)`
  background-color: var(--background-secondary);
  padding: 20px 50px;
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default LayoutDashboard;
