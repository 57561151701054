import { lazy } from 'react';
import EnhancedRoute from 'EnhancedRoute';
import { Switch } from 'react-router-dom';
import LayoutAnonymous from 'components/Layout/LayoutAnonymous';
import LayoutAuth from 'components/Layout/LayoutAuth';
import LayoutDashboard from 'components/Layout/LayoutDashboard';
import LayoutMHPDashboard from 'components/Layout/LayoutMHPDashboard';

const AccountConfirmationPage = lazy(() => import('pages/AccountConfirmationPage'));
const AssessmentPage = lazy(() => import('pages/AssessmentPage'));
const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'));
const CurrentUserPage = lazy(() => import('pages/CurrentUserPage'));
const DevicesPage = lazy(() => import('pages/DevicesPage'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const MetaOnePage = lazy(() => import('pages/MetaOnePage'));
const MyJourneyPage = lazy(() => import('pages/MyJourneyPage'));
const MyJourneyPursuitDetailsPage = lazy(() => import('pages/MyJourneyPursuitDetailsPage'));
const OneTimePasswordPage = lazy(() => import('pages/OneTimePasswordPage'));
const RegistrationPage = lazy(() => import('pages/RegistrationPage'));
const RewardsPage = lazy(() => import('pages/RewardsPage'));
const RewardsRedeemedPage = lazy(() => import('pages/RewardsRedeemedPage'));
const ScaleInsightsPage = lazy(() => import('pages/ScaleInsightsPage'));
const ScaleOrderPage = lazy(() => import('pages/ScaleOrderPage'));
const TaskPage = lazy(() => import('pages/TaskPage'));
const UserByIdPage = lazy(() => import('pages/UserByIdPage'));

function App() {
  return (
    <Switch>
      <EnhancedRoute
        path="/"
        exact
        layout={LayoutDashboard}
        content={CurrentUserPage}
        requiresAuth
      />

      <EnhancedRoute
        path="/profile/:id/"
        layout={LayoutDashboard}
        content={UserByIdPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/assessment/"
        layout={LayoutDashboard}
        content={AssessmentPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/rewards/"
        exact
        layout={LayoutDashboard}
        content={RewardsPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/rewards/:id"
        exact
        layout={LayoutDashboard}
        content={RewardsPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/rewards-redeemed/"
        exact
        layout={LayoutDashboard}
        content={RewardsRedeemedPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/rewards-redeemed/:id"
        exact
        layout={LayoutDashboard}
        content={RewardsRedeemedPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/devices/"
        exact
        layout={LayoutDashboard}
        content={DevicesPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/my-journey"
        exact
        layout={LayoutDashboard}
        content={MyJourneyPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/my-journey/:id"
        layout={LayoutDashboard}
        content={MyJourneyPursuitDetailsPage}
        requiresAuth
      />
      <EnhancedRoute path="/login" layout={LayoutAuth} content={LoginPage} />
      <EnhancedRoute path="/otp" layout={LayoutAuth} content={OneTimePasswordPage} />
      <EnhancedRoute path="/forgot-password" layout={LayoutAuth} content={ForgotPasswordPage} />
      <EnhancedRoute path="/registration" layout={LayoutAuth} content={RegistrationPage} />
      <EnhancedRoute
        path="/scale-insights"
        layout={LayoutDashboard}
        content={ScaleInsightsPage}
        requiresAuth
      />
      <EnhancedRoute
        path="/scale-order"
        layout={LayoutDashboard}
        content={ScaleOrderPage}
        requiresAuth
      />
      <EnhancedRoute path="/create-account" layout={LayoutAuth} content={CreateAccountPage} />
      <EnhancedRoute
        path="/account-confirmation"
        layout={LayoutAuth}
        content={AccountConfirmationPage}
      />
      <EnhancedRoute
        path="/meta-one/:id"
        layout={LayoutMHPDashboard}
        content={MetaOnePage}
        requiresAuth
      />
      <EnhancedRoute
        path="/meta-one"
        layout={LayoutMHPDashboard}
        content={MetaOnePage}
        requiresAuth
      />
      <EnhancedRoute path="/task" layout={LayoutMHPDashboard} content={TaskPage} requiresAuth />
      <EnhancedRoute path="*" layout={LayoutAnonymous} content={ErrorPage} />
    </Switch>
  );
}

export default App;
