import bodytraceBlue from './bodytrace-blue.png';
import ic_lg_ch_coronavirus from './ic_lg_ch_coronavirus.svg';
import ic_lg_ch_healthyeater from './ic_lg_ch_healthyeater.svg';
import ic_lg_ch_hearthealthyhero from './ic_lg_ch_hearthealthyhero.svg';
import ic_lg_ch_keepcalm from './ic_lg_ch_keepcalm.svg';
import ic_lg_ch_nonsmoker from './ic_lg_ch_nonsmoker.svg';
import ic_lg_ch_wellbalancedbod from './ic_lg_ch_wellbalancedbod.svg';
import icLgWbgCoinAdv from './ic_lg_wbg-coin-adv.svg';
import appleHealthLogo from './ic_md_apptracker_applehealth.png';
import emfitLogo from './ic_md_apptracker_emfit.png';
import fitbitLogo from './ic_md_apptracker_fitbit.png';
import garminLogo from './ic_md_apptracker_garmin.png';
import ihealthLogo from './ic_md_apptracker_ihealth.png';
import misfit from './ic_md_apptracker_misfit.png';
import omronLogo from './ic_md_apptracker_omron.png';
import polarLogo from './ic_md_apptracker_polar.png';
import stravaLogo from './ic_md_apptracker_strava.png';
import striivLogo from './ic_md_apptracker_striiv.png';
import underarmourLogo from './ic_md_apptracker_underarmour.png';
import withingsLogo from './ic_md_apptracker_withings.png';
import yooFitnessLogo from './ic_md_apptracker_yoo_fitness.png';
import icMdCash from './ic_md_cash.svg';
import icMdCleanCar from './ic_md_clean_car.svg';
import icMdMediaMovie from './ic_md_media_movie.svg';
import icMdMoodDiary from './ic_md_mood_diary.svg';
import ic_sm_mentalemotional_active from './ic_sm_mentalemotional_active.svg';
import ic_sm_mentalemotional_inactive from './ic_sm_mentalemotional_inactive.svg';
import ic_sm_nutrition_active from './ic_sm_nutrition_active.svg';
import ic_sm_nutrition_inactive from './ic_sm_nutrition_inactive.svg';
import ic_sm_pa_active from './ic_sm_pa_active.svg';
import ic_sm_pa_inactive from './ic_sm_pa_inactive.svg';
import ic_sm_selfcare_active from './ic_sm_selfcare_active.svg';
import ic_sm_selfcare_inactive from './ic_sm_selfcare_inactive.svg';
import illCleanCar from './ill_clean_car.svg';
import ill_covid19 from './ill_covid19.svg';
import illDiaryGeneric from './ill_diary_generic.svg';
import ic_tasks_board from './ic_tasks_board.svg';
import ill_exercise_walking from './ill_exercise_walking.svg';
import ill_food_fiber from './ill_food_fiber.svg';
import illHwa from './ill_hwa.svg';
import illNoHealthRisks from './ill_no_health_risks.svg';
import illOverviewChat from './ill_overview_chat.svg';
import ill_stressimpact from './ill_stressimpact.svg';
import ill_tobacco from './ill_tobacco.svg';
import trackWeightLossIllustration from './ill_trackweightloss.svg';
import illVideoGeneric from './ill_video_generic.svg';
import ill_waistline from './ill_waistline.svg';
import scale30deg from './scale-30deg.jpeg';
import scaleTop from './scale-top.jpeg';

export { ReactComponent as BgWaves } from './bg-waves.svg';
export { ReactComponent as Password } from './ill_password.svg';
export { ReactComponent as Lifesaver } from './ic_md_lifesaver.svg';
export { ReactComponent as NewUserFail } from './ic_md_newuser_fail.svg';
export { ReactComponent as NewUser } from './ic_md_newuser.svg';
export { ReactComponent as ThumbUp } from './ic_md_thumbup.svg';
export { ReactComponent as EmailCode } from './ill_emailcode.svg';
export { ReactComponent as PasswordEmailSent } from './ill_passwordemailsent.svg';
export { ReactComponent as PasswordReq } from './ill_passwordreq.svg';
export { ReactComponent as PasswordResetYay } from './ill_passwordresetyay.svg';
export { ReactComponent as RegisterFound } from './ill_registerfound.svg';
export { ReactComponent as RegisterNew } from './ill_registernew.svg';
export { ReactComponent as SearchMembers } from './ill_search_members.svg';
export { ReactComponent as WelcomeCollage } from './ill_welcomecollage.svg';
export { ReactComponent as Logo } from './logo.svg';
export { ReactComponent as NewTerms } from './ill_newterms.svg';
export { ReactComponent as HwaIllustration } from './HWA_Illustration.svg';
export { ReactComponent as IconInfocircle } from './icon-infocircle.svg';
export { ReactComponent as IconMdSurveyMedical } from './ic_md_survey_medical.svg';
export { ReactComponent as Appstore } from './Appstore.svg';
export { ReactComponent as Playstore } from './Playstore.svg';
export { ReactComponent as Qrcode } from './qrcode.svg';
export { ReactComponent as CloseIcon } from './ic-close.svg';
export { ReactComponent as CoinShopIllustration } from './ill-coinshop.svg';
export { ReactComponent as RewardsIllustration } from './ill_rewards.svg';
export { ReactComponent as CoinActiveIcon } from './ic_sm_coin_active.svg';
export { ReactComponent as MissingPageIllustration } from './404.svg';
export { ReactComponent as WearableIllustration } from './ill_wearable.svg';
export { ReactComponent as HowToTrackIllustration } from './ill_howtotrack2.svg';
export { ReactComponent as BelmontLogo } from './belmont_logo.svg';
export { ReactComponent as DietDomainIcon } from './ic_md_domain_diet.svg';
export { ReactComponent as MentalDomainIcon } from './ic_md_domain_mental.svg';
export { ReactComponent as PhysactivityDomainIcon } from './ic_md_domain_physactivity.svg';
export { ReactComponent as PreventionDomainIcon } from './ic_md_domain_prevention.svg';
export { ReactComponent as CalendarIcon } from './ic_xs_calendar.svg';
export { ReactComponent as CoinsIcon } from './ic_md_coins.svg';
export { ReactComponent as CoinsAllIcon } from './ic_md_coinsall.svg';
export { ReactComponent as OverviewChallengeIllustration } from './ill_overview_challenge.svg';
export { ReactComponent as TasksBoardIcon } from './ic_tasks_board.svg';
export { ReactComponent as PinkBlobBackground } from './background_pink_blob.svg';
export { ReactComponent as IconCalendarFairlyOften } from './ic_md_cal_fairlyoften.svg';
export { bodytraceBlue };
export { scaleTop };
export { scale30deg };
export { trackWeightLossIllustration };
export { illHwa };
export { illNoHealthRisks };
export { illOverviewChat };
export { appleHealthLogo };
export { emfitLogo };
export { fitbitLogo };
export { garminLogo };
export { ihealthLogo };
export { misfit };
export { omronLogo };
export { polarLogo };
export { stravaLogo };
export { striivLogo };
export { underarmourLogo };
export { withingsLogo };
export { yooFitnessLogo };
export { icMdCash };
export { icLgWbgCoinAdv };
export { ic_lg_ch_keepcalm };
export { ic_sm_selfcare_active };
export { ic_lg_ch_coronavirus };
export { ic_lg_ch_wellbalancedbod };
export { ic_sm_pa_active };
export { ic_lg_ch_hearthealthyhero };
export { ic_lg_ch_healthyeater };
export { ic_sm_nutrition_active };
export { ic_sm_pa_inactive };
export { ic_sm_mentalemotional_inactive };
export { ic_sm_selfcare_inactive };
export { ic_sm_nutrition_inactive };
export { ic_sm_mentalemotional_active };
export { ic_lg_ch_nonsmoker };
export { ill_tobacco };
export { ill_food_fiber };
export { ill_stressimpact };
export { ic_tasks_board };
export { ill_exercise_walking };
export { ill_waistline };
export { ill_covid19 };
export { illCleanCar };
export { icMdCleanCar };
export { icMdMoodDiary };
export { illDiaryGeneric };
export { icMdMediaMovie };
export { illVideoGeneric };

interface LocalIcons {
  [key: string]: string;
}

export const localIcons: LocalIcons = {
  ic_md_cash: icMdCash,
  'ic_lg_wbg-coin-adv': icLgWbgCoinAdv,
  ic_lg_ch_keepcalm,
  ic_sm_selfcare_active,
  ic_lg_ch_coronavirus,
  ic_lg_ch_wellbalancedbod,
  ic_sm_pa_active,
  ic_lg_ch_hearthealthyhero,
  ic_lg_ch_healthyeater,
  ic_sm_nutrition_active,
  ic_sm_pa_inactive,
  ic_sm_mentalemotional_inactive,
  ic_sm_selfcare_inactive,
  ic_sm_nutrition_inactive,
  ic_sm_mentalemotional_active,
  ic_lg_ch_nonsmoker,
  ill_tobacco,
  ill_food_fiber,
  ill_stressimpact,
  ic_tasks_board,
  ill_exercise_walking,
  ill_waistline,
  ill_covid19,
  illHwa,
  illNoHealthRisks,
  illOverviewChat,
  illCleanCar,
  icMdCleanCar,
  icMdMoodDiary,
  icMdCash,
  illDiaryGeneric,
  icMdMediaMovie,
  illVideoGeneric,
};
