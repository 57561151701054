import styled from '@emotion/styled';
import { Button, Result } from 'antd';
import useAuth from 'api/authProvider';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MissingPageIllustration } from 'assets';

const ErrorPage = () => {
  const { isLoggedIn, hasActivePursuit } = useAuth();
  const { t } = useTranslation();

  return (
    <Result
      title={<StyledTitle>{t('error.thanksForStoppingBy')}</StyledTitle>}
      icon={<StyledMissingPageIllustration />}
      extra={
        <>
          {isLoggedIn && (
            <>
              {hasActivePursuit && (
                <>
                  <Link to="/my-journey" component={Button} type="primary">
                    {t('layout.myJourney')}
                  </Link>
                  <Link to="/" component={Button} type="primary">
                    {t('layout.dashboard')}
                  </Link>
                  <Link to="/rewards" component={Button} type="primary">
                    {t('layout.rewards')}
                  </Link>
                </>
              )}
              {!hasActivePursuit && (
                <Link to="/my-journey" component={Button} type="primary">
                  {t('layout.myJourney')}
                </Link>
              )}
            </>
          )}
          {!isLoggedIn && (
            <>
              <Link to="/login" component={Button} type="primary">
                {t('auth.backToLogin')}
              </Link>
            </>
          )}
        </>
      }
    />
  );
};

const StyledMissingPageIllustration = styled(MissingPageIllustration)`
  max-width: 100%;
`;

const StyledTitle = styled.h1`
  max-width: 25rem;
  margin: 0 auto;
  font-size: 1.125rem;
  font-weight: 400;
`;

export default ErrorPage;
