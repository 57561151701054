import { formatPhoneCode, parsePhone, phoneToString } from 'utils/parsePhone';
import prepareBiometricPostRequests from 'utils/prepareBiometricPostRequest';
import resizeImage from 'utils/resizeImage';
import restClient from './restClient';
import {
  AccountCreationData,
  AssessmentAnswer,
  BackendIcon,
  BiometricData,
  BiometricInputProps,
  Device,
  GraphsAvgData,
  LoginData,
  Measurement,
  OTPLoginData,
  OrderScaleData,
  OrderScaleResponse,
  OrderScaleStatus,
  ProfileNotificationAction,
  ProfileNotifications,
  Pursuit,
  Pursuits,
  RegistrationInfo,
  Rewards,
  Timezone,
  User,
  ValidicProfile,
} from 'backendTypes';

class Backend {
  constructor() {
    const accessToken = window.localStorage.getItem('auth:accessToken');
    if (accessToken) {
      restClient.defaults.headers.common.authorization = `Token ${accessToken}`;
    }
  }

  async login(credentials: LoginData) {
    delete restClient.defaults.headers.common.authorization;

    const { data } = await restClient('/api/login/', {
      method: 'POST',
      data: credentials,
    });
    const accessToken = data.token as string;
    window.localStorage.setItem('auth:accessToken', accessToken);
    restClient.defaults.headers.common.authorization = `Token ${accessToken}`;
  }

  async getCurrentUser(): Promise<User | null> {
    if (window.localStorage.getItem('auth:accessToken')) {
      const response = await restClient('/api/profile/');

      if (response?.data) {
        const { data } = response;

        return {
          ...data,
          phoneCell: parsePhone(data.phoneCell),
          workPhone: parsePhone(data.workPhone),
          hasAcceptedPolicy: data.isStaff || data.hasAcceptedPolicy,
        };
      }
      return null;
    } else {
      return null;
    }
  }

  async editCurrentUser(userData: User) {
    const { data } = await restClient(`/api/profile/`, {
      method: 'PATCH',
      data: {
        ...userData,
        phoneCell: phoneToString(userData.phoneCell),
        phoneCode: formatPhoneCode(userData.phoneCell),
        workPhone: phoneToString(userData.workPhone),
        workPhoneCode: formatPhoneCode(userData.workPhone),
      },
    });
    return data;
  }

  logout() {
    window.localStorage.removeItem('auth:accessToken');
    delete restClient.defaults.headers.common.authorization;
  }

  async getOneTimePassword(login: string): Promise<string> {
    const { data } = await restClient('/api/otp/', {
      method: 'POST',
      data: { login },
    });
    return data.timestamp;
  }

  async loginWithOneTimePassword(credentials: OTPLoginData): Promise<void> {
    delete restClient.defaults.headers.common.authorization;

    const { data } = await restClient('/api/otp/login/', {
      method: 'POST',
      data: credentials,
    });
    const accessToken = data.token as string;
    window.localStorage.setItem('auth:accessToken', accessToken);
    restClient.defaults.headers.common.authorization = `Token ${accessToken}`;
  }

  async resetPassword(email: string) {
    await restClient('/api/reset_password/', {
      method: 'POST',
      data: { email },
    });
  }

  async setNewPassword(password: string) {
    // TODO: Handle the endpoint.
    console.log('password: ', password);
  }

  async getUser(eligibilityId: string): Promise<User> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/`, {
      method: 'GET',
    });
    return {
      ...data,
      phoneCell: parsePhone(data.phoneCell),
      workPhone: parsePhone(data.workPhone),
    };
  }

  async editUser(userData: User, eligibilityId: string) {
    const { data } = await restClient(`/api/profile/${eligibilityId}/`, {
      method: 'PATCH',
      data: {
        ...userData,
        phoneCell: phoneToString(userData.phoneCell),
        phoneCode: formatPhoneCode(userData.phoneCell),
        workPhone: phoneToString(userData.workPhone),
        workPhoneCode: formatPhoneCode(userData.workPhone),
      },
    });
    return data;
  }

  async getBiometricData(eligibilityId: string) {
    const { data } = await restClient(`/api/profile/${eligibilityId}/measurements/`, {
      method: 'GET',
    });
    return data;
  }

  async addBiometric(
    values: BiometricInputProps,
    eligibilityId: string,
    initialValues: BiometricData,
    measurement: Measurement
  ) {
    // prepareBiometricPostRequests returns an array of filtered request payloads.
    const postRequests = prepareBiometricPostRequests(
      values,
      initialValues,
      eligibilityId,
      measurement
    );

    for (let index = 0; index < postRequests.length; index++) {
      const payload = postRequests[index];
      await restClient(`/api/measure/`, {
        method: 'POST',
        data: payload,
      });
    }
  }

  async updateUserAvatar(eligibilityId: string, file: File) {
    const image = await resizeImage(file);
    const { data } = await restClient('/api/profile/change_avatar/', {
      method: 'POST',
      data: { image, eligibilityId },
    });
    return data.image;
  }

  async updateCurrentUserAvatar(file: File) {
    const image = await resizeImage(file);
    const { data } = await restClient('/api/profile/change_avatar/', {
      method: 'POST',
      data: { image },
    });
    return data.image;
  }

  async getAssessment() {
    const { data } = await restClient(`/api/profile/intro_assessment/?new_version=True`, {
      method: 'GET',
    });
    return data;
  }

  async getAssessmentQuestion(questionId: number) {
    const { data } = await restClient(`api/profile/intro_assessment/question/${questionId}/`, {
      method: 'GET',
    });
    return data;
  }

  async postAssessmentAnswer(data: AssessmentAnswer) {
    const resp = await restClient('/api/assessment/answer/', {
      method: 'POST',
      data,
    });
    return resp;
  }

  async finishAssessment() {
    const data = { completed: true };
    const resp = await restClient('/api/profile/intro_assessment/', {
      method: 'PATCH',
      data,
    });
    return resp;
  }

  async validateRegistrationCode(registrationCode: string) {
    const { data } = await restClient('/api/registration/eligibility-member/', {
      method: 'GET',
      params: { registrationCode },
    });
    return data as RegistrationInfo;
  }

  async findMatchingProfile(criteria?: RegistrationInfo) {
    const { data } = await restClient('/api/registration/eligibility-member/', {
      method: 'GET',
      params: criteria,
    });
    return data as RegistrationInfo;
  }

  async createUserAccount(creationData: AccountCreationData) {
    const { phoneNumber, ...rest } = creationData;
    const parsedPhoneNumber = phoneToString(creationData.phoneNumber);
    const { data } = await restClient('/api/registration/user/', {
      method: 'POST',
      data: {
        ...rest,
        ...(parsedPhoneNumber ? { phoneNumber: parsedPhoneNumber } : {}),
      },
    });
    return data as AccountCreationData;
  }

  async createEligibilityMemberAccount(creationData: AccountCreationData) {
    const { data } = await restClient('/api/registration/create-account/', {
      method: 'POST',
      data: creationData,
    });
    return data as AccountCreationData;
  }

  async resendVerificationEmail(email: string) {
    const resp = await restClient('/api/registration/email/verification-mail/', {
      method: 'POST',
      data: { email },
    });
    return resp;
  }

  async verifyUser(registrationCode: string) {
    const resp = await restClient('/api/registration/email/verification-token/', {
      method: 'GET',
      params: { registrationCode },
    });
    return resp;
  }

  async agreePolicy(id: string) {
    const { data } = await restClient('/api/profile/policy/', { method: 'POST' });
    return data;
  }

  async getRewards(): Promise<Rewards> {
    const { data } = await restClient('/api/profile/rewards/', { method: 'GET' });
    return data;
  }

  async getRewardsForUser(eligibilityId: string): Promise<Rewards> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/rewards/`, {
      method: 'GET',
    });
    return data;
  }

  async redeemReward(rewardId: number): Promise<void> {
    const { data } = await restClient(`/api/profile/rewards/redeem/`, {
      method: 'POST',
      data: { rewardId },
    });
    return data;
  }

  async redeemRewardForUser(rewardId: number, eligibilityId: string): Promise<void> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/rewards/redeem/`, {
      method: 'POST',
      data: { rewardId },
    });
    return data;
  }

  async getIcon(iconName: string): Promise<BackendIcon> {
    const { data } = await restClient(`/api/icon/${iconName}/`, { method: 'GET' });
    return data;
  }

  async getValidicProfile(): Promise<ValidicProfile> {
    const { data } = await restClient('/api/profile/validic/user', { method: 'GET' });
    return data;
  }

  async connectMyDevice(): Promise<{ url: string }> {
    const { data } = await restClient('/api/profile/devices/connect/', { method: 'GET' });
    return data;
  }

  async getMyDevices(): Promise<Device[]> {
    const { data } = await restClient('/api/profile/devices/', { method: 'GET' });
    return data;
  }

  async getUserDevices(eligibilityId: string): Promise<Device[]> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/devices/`, {
      method: 'GET',
    });
    return data;
  }

  async disconnectMyDevice(deviceId: number): Promise<void> {
    const { data } = await restClient(`/api/profile/devices/`, {
      method: 'PUT',
      data: { deviceId, actionType: 'disconnect' },
    });
    return data;
  }

  async disconnectUserDevice(deviceId: number, eligibilityId: string): Promise<void> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/devices/`, {
      method: 'PUT',
      data: { deviceId, actionType: 'disconnect' },
    });
    return data;
  }

  async getPursuits(): Promise<Pursuits> {
    const { data } = await restClient('/api/pursuits/', { method: 'GET' });
    return data;
  }

  async getActivePursuit(): Promise<Pursuit> {
    const { data } = await restClient('/api/active_pursuit/', {
      method: 'GET',
      params: {
        ignoreRecentAssessment: 1,
      },
    });
    return data;
  }

  async pickPursuit(pursuitId: number): Promise<void> {
    const { data } = await restClient('/api/pursuits/', {
      method: 'POST',
      data: { pursuitId },
    });
    return data;
  }

  async chooseNextPursuit(): Promise<void> {
    const { data } = await restClient('/api/pursuits/', { method: 'POST' });
    return data;
  }

  async abandonPursuit(): Promise<void> {
    const { data } = await restClient('/api/active_pursuit/', {
      method: 'POST',
      data: { abandon: true },
    });
    return data;
  }

  async getUserDataForOrderScale(): Promise<OrderScaleData> {
    const { data } = await restClient('/bodytrace/order-scale/', {
      method: 'GET',
    });
    return data;
  }

  async validateOrderScale(userData: OrderScaleData): Promise<OrderScaleData> {
    const { data } = await restClient('/bodytrace/order-scale/', {
      method: 'POST',
      data: {
        ...userData,
        phoneCell: phoneToString(userData.phoneCell),
        phoneCode: formatPhoneCode(userData.phoneCell),
        validate: true,
      },
    });
    return data;
  }

  async orderScale(userData: OrderScaleData): Promise<OrderScaleResponse> {
    const { data } = await restClient('/bodytrace/order-scale/', {
      method: 'POST',
      data: { ...userData },
    });
    return data;
  }

  async checkOrderScaleStatus(): Promise<OrderScaleStatus> {
    const { data } = await restClient('/bodytrace/check-order/', {
      method: 'GET',
    });
    return data;
  }

  async getProfileNotifications(): Promise<ProfileNotifications> {
    const { data } = await restClient('/api/profile/notifications/', {
      method: 'GET',
    });
    return data;
  }

  async updateProfileNotifications(
    profileNotificationAction: ProfileNotificationAction
  ): Promise<void> {
    const { data } = await restClient('/api/profile/notifications/mass-action/', {
      method: 'PUT',
      data: profileNotificationAction,
    });
    return data;
  }

  async getGraphsWeeklyData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/daily-average/', {
      method: 'GET',
    });
    return data;
  }

  async getGraphsMonthlyData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/daily-average/', {
      method: 'GET',
      params: {
        days: 30,
      },
    });
    return data;
  }

  async getGraphsSixMonthsData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/weekly-average/', {
      method: 'GET',
      params: {
        weeks: 24,
      },
    });
    return data;
  }

  async getGraphsLastYearData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/monthly-average/', {
      method: 'GET',
    });
    return data;
  }

  async getTimezone(): Promise<Timezone> {
    const { data } = await restClient('/api/profile/timezone/', {
      method: 'GET',
    });
    return data;
  }

  async setTimezone(timezone: string): Promise<Timezone> {
    const { data } = await restClient('/api/profile/timezone/', {
      method: 'PATCH',
      data: { timezone },
    });
    return data;
  }
}

export const backend = new Backend();
