import styled from '@emotion/styled';
import { Avatar } from 'antd';
import formatInitials from 'utils/formatInitials';

interface AvatarProps {
  image?: string;
  firstName?: string;
  lastName?: string;
  large?: boolean;
}

const UserAvatar = ({ image, firstName, lastName, large }: AvatarProps) => {
  const Component = large ? LargeAvatar : Avatar;
  return (
    <>
      {image ? (
        <Component size="large" src={image} />
      ) : (
        <Component size="large">{formatInitials(firstName, lastName)}</Component>
      )}
    </>
  );
};

const LargeAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 60px;
`;

export default UserAvatar;
