import { Suspense } from 'react';
import useAuth from 'api/authProvider';
import { RouteProps } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import redirectTo from 'utils/redirectTo';
import AppLoader from 'components/AppLoader';

export interface EnhancedRouteProps extends RouteProps {
  path: string;
  exact?: boolean;
  requiresAuth?: boolean;
  layout: React.ComponentType<{ children: React.ReactNode }>;
  content: React.ComponentType;
  layoutProps?: Object;
}

const EnhancedRoute = ({
  layout: Layout,
  content: Content,
  requiresAuth,
  layoutProps,
  ...rest
}: EnhancedRouteProps) => {
  const { pathname } = useLocation();
  const { isLoggedIn, isLoading, hasAssessmentCompleted } = useAuth();

  if (isLoading && requiresAuth) {
    return <AppLoader />;
  }

  if (requiresAuth && !isLoggedIn) {
    redirectTo('login', pathname);
  }

  // TODO: replace with check for missingAssessments from /api/profile/
  if (requiresAuth && isLoggedIn && !hasAssessmentCompleted) {
    redirectTo('assessment', pathname);
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Suspense fallback={<AppLoader />}>
          <Layout {...layoutProps}>
            <Content />
          </Layout>
        </Suspense>
      )}
    />
  );
};

export default EnhancedRoute;
