import browserHistory from 'browserHistory';
import { stringify } from 'query-string';

export default function redirectTo(target: string, source: string) {
  if (!source.includes(`/${target}`)) {
    if (source === '/') {
      browserHistory.replace(`/${target}`);
    } else {
      const direction = target.startsWith('/') ? target.substring(1) : target;
      browserHistory.push(`/${direction}?${stringify({ source: source })}`);
    }
  }
}
