import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Card } from 'antd';
import BgWaves from 'assets/bg-waves.svg';
import { useTranslation } from 'react-i18next';
import { Logo } from 'assets';

interface Props {
  children: ReactNode;
}

const LayoutAuth = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <LayoutContainer>
      <ChildrenContainer>
        <StyledCard>{children}</StyledCard>
      </ChildrenContainer>
      <Footer>
        <LogosContainer>
          <StyledLogo />
        </LogosContainer>
        <TextContainer>
          <Text>{t('auth.preventivePlan')}</Text>
          <Text>{t('auth.allRightsReserved')}</Text>
        </TextContainer>
      </Footer>
      <BackgroundContainer>
        <BlueBackground />
        <WhiteBackground />
      </BackgroundContainer>
    </LayoutContainer>
  );
};

const LogosContainer = styled.div`
  width: 100%;
  max-width: 29.0625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogo = styled(Logo)`
  width: 100%;
  max-width: 9.875rem;
  margin: 1rem auto 0 auto;
`;

const TextContainer = styled.div`
  margin: 17px 10px;
`;

const Text = styled.p`
  margin-bottom: 0.5rem;
  font-size: 15px;
  line-height: 24px;
  color: var(--text-charcoal);
  opacity: 0.5;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
`;

const LayoutContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ChildrenContainer = styled.div`
  margin: 1rem 10px;
`;

const StyledCard = styled(Card)`
  width: 465px;
  margin: 1rem auto;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #00000029;
  @media (max-width: 485px) {
    width: 100%;
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const BlueBackground = styled.div`
  height: 50%;
  background-color: var(--background-accent);
  background-image: url(${BgWaves});
  background-repeat: no-repeat;
  background-position: 50% 101%;
  @media (min-width: 1440px) {
    background-size: contain;
  }
`;

const WhiteBackground = styled.div`
  height: 50%;
  background-color: var(--background-primary);
`;

export default LayoutAuth;
