import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import useAuth from 'api/authProvider';
import { AxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorPage from 'pages/ErrorPage';

const ErrorFallback = ({ error }: FallbackProps) => {
  const { hasActivePursuit } = useAuth();
  const err = error as AxiosError;

  const { t } = useTranslation();

  const handledStatuses = [404, 403, 500]; // Statuses handled by antd Result component.

  const displayedStatus =
    err.response && handledStatuses.includes(err.response.status)
      ? err.response.status.toString()
      : 'error';

  const returnSubtitle = () => {
    switch (displayedStatus) {
      case '403':
        return t('error.403Descr');
      case '404':
        return t('error.404Descr');
      default:
        return t('Sorry, something went wrong.');
    }
  };

  return (
    <>
      {displayedStatus === '404' ? (
        <ErrorPage />
      ) : (
        <Result
          status={displayedStatus as ResultStatusType}
          title={displayedStatus}
          subTitle={returnSubtitle()}
          extra={
            <>
              <Link to="/my-journey" component={Button} type="primary">
                {t('layout.myJourney')}
              </Link>
              <Link to="/" component={Button} type="primary">
                {t('layout.dashboard')}
              </Link>
              {hasActivePursuit && (
                <Link to="/rewards" component={Button} type="primary">
                  {t('layout.rewards')}
                </Link>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default ErrorFallback;
